import fetch from 'cross-fetch'
import {API_HOST} from '../../environment'

export const POST_CONTACTFORM = 'POST_CONTACTFORM'
export const RECEIVE_CONTACTFORM_RESULT = 'RECEIVE_CONTACTFORM_RESULT'

function postedContactForm() {
    return {
      type: POST_CONTACTFORM
    }
  }
  
  function receiveContactFormResults(success, json, notificationContext) {
    window && window.umami && window.umami.trackEvent(success ? "erfolgreich gesendet" : "Fehler", 'Kontaktformular')
    success ? notificationContext.success("Kontaktformular erfolgreich gesendet") : notificationContext.error("Fehler beim Senden des Kontaktformulars")
    return {
      type: RECEIVE_CONTACTFORM_RESULT,
      result: {
        success: success,
        error: json != undefined ? json.missing : ''
        }
    }
  }
  
  export function sendContact(fields, notificationContext){
    return dispatch => {
        dispatch(postedContactForm())
        return fetch(API_HOST + '/ticket.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: generateFormBody(fields)
      }).then(response => response.status != 200 ? response.json().then(json => dispatch(receiveContactFormResults(false, json, notificationContext))) : dispatch(receiveContactFormResults(true, undefined, notificationContext))).catch((error) => dispatch(receiveContactFormResults(false, error, notificationContext)))   
    }
  }

const generateFormBody = (fields) => {
  let formBody = [];
  for (let property in fields) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(fields[property]);
      formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody
}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
