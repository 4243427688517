import {POST_CONTACTFORM, RECEIVE_CONTACTFORM_RESULT} from '../actions'


const initialContactFormState = {
    isPosting : false,
    isFinished : false,
    success : false,
    error : []
}


export default function sendContactForm(
    state = initialContactFormState,
    action
  ) {
    switch (action.type) {
      case POST_CONTACTFORM:
        return Object.assign({}, state, {
              isPosting : true,
              isFinished : false,
              success : false,
              error : {}
        })
      case RECEIVE_CONTACTFORM_RESULT:
        return Object.assign({}, state, {
              isPosting : false,
              isFinished : true,
              success : action.result.success,
              error : action.result.error
        })
      default:
        return state
    }
  }