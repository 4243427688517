import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './reducer'


export default function configureStore(preloadedState) {
  if (process.env.NODE_ENV === 'production') {
    return createStore(
      rootReducer,
      preloadedState,
      compose(
      applyMiddleware(thunkMiddleware))
    )
  } else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
      rootReducer,
      preloadedState,
      composeEnhancers(
      applyMiddleware(thunkMiddleware))
    )
  }
}